.marketing-section {
    .MuiTypography-root{ 
        color: #151515;
    }
}

.market-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 395px);
    grid-template-rows: repeat(2, 130px);
    gap: 32px 26px; 
}

.market-grid__item {
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 8px;
    padding-right: 10px;
    background-color: #FFFFFF;
    border-radius: 20px;
    white-space: pre-wrap;
}
