:root {
  --swiper-navigation-size: 18px;
}

.testimonial-carousel .carousel-container {
  padding-top: 78px;
  padding-bottom: 100px;
  padding-left: 40px;
  user-select: none
}

 .testimonial-carousel .swiper-slide {
    height: auto; /* растянуть по вертикали */
  }


   
  .swiper-button-prev,
  .swiper-button-next {
  padding: 15px;
  position: absolute;
  transform: translate(0, -35%);
  color: #FF7700 !important;
  font-size: 18px !important;
  transition: transform .5s  ease-in-out;

}


 .swiper-button-prev:hover,
 .swiper-button-next:hover {
  transform: scale(1, 1.2) translate(0, -30%);
}

 .swiper-button-prev {
left: -15px;
}

 .swiper-button-next {
  right: -15px;
}