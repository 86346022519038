.partner-carousel {
  user-select: none;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper {
    padding: 0 40px 0 55px;
  }

  .swiper-partner-button-prev,
  .swiper-partner-button-next{
    padding: 15px ;
    position: absolute;
    color: #FF7700 !important;
    font-size: 18px !important;
    transition: transform .5s  ease-in-out;
    transform: translate(0, -55%); 
    background-color: #f9f9f9;
     /* взять пипеткой цвет серый с прозрачностью чтобы получить цвет плотный*/
    font-family: swiper-icons;

    /* стили swiper */
    top: var(--swiper-navigation-top-offset, 50%);
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;


   }

  .swiper-partner-button-prev:hover,
  .swiper-partner-button-next:hover {
   transform: scale(1, 1.2) translate(0, -50%);
 }

  .swiper-partner-button-prev {
    left: 0;
    box-shadow: 15px 3px 15px 7px #f9f9f9;
    &:after {
      content: 'prev'
    }
  }

  .swiper-partner-button-next {
    right: 0;
    box-shadow: -15px 3px 15px 7px #f9f9f9;
    &:after {
      content: 'next'
    }
  } 


  
}