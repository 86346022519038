
.telephony-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 160px 85px 136px 160px;
    gap: 16px;
}
    
.div {
    padding-left: 40px;
    padding-top: 40px;
    padding-right: 10px;
    border: 1px solid #FFFFFF26;
    border-radius: 20px;
    position: relative;
}

.div3 {
    grid-row: span 2 / span 2;
}

.div4 {
    grid-row: span 3 / span 3;
}

.div5 {
    grid-row: span 2 / span 2;
}

.div6 {
    grid-column-start: 3;
    grid-row-start: 3;
}

.div7 {
    grid-column-start: 2;
    grid-row-start: 4;
}

.div8 {
    grid-column-start: 3;
    grid-row-start: 4;
}

/* .telephony-grid__envelopes {
    width: 205px;
    height: auto;
    position: absolute;
    right: 25px;
    bottom: 15px;
    border: 1px solid red;
}

.telephony-grid__envelopes__inner {
    position: relative;
} */


.telephony-grid__envelopes--back {
    position: absolute;
    bottom: 30px;
    right: 160px;
    
}

.telephony-grid__envelopes--mid {     
    position: absolute;
    right: 40px;
    bottom: 15px;
    
}

.telephony-grid__envelopes--front {     
    position: absolute;
    right: 25px;
    bottom: 80px;
    
}

.telephony-grid__earth {
    width: 225px;
    position: absolute;
    right: 45px;
    bottom: 40px;
    /* animation: earth-move 3s infinite; */
    /* animation-timing-function: linear; */

    img {
        width: 100%;
    }
}


/* @keyframes earth-move {
    0%, 100%{
        transform: translate(0, 0);
    }
    50% {
        transform: translate(5px, 10px);
    }
  } */

.telephony-grid__chart--left {
    position: absolute;
    bottom: 46px;
    right: 116px;
    /* border: 1px solid red; */
    
    /* animation: up-down-fast 2s infinite; */
    /* animation-timing-function: linear; */
}

/* @keyframes up-down-fast {
    50% {
      bottom: 56px;
    }
  } */

.telephony-grid__chart--mid {
    position: absolute;
    bottom: 25px;
    right: 80px;
    /* border: 1px solid red; */
    /* animation: up-down-middle 2s infinite; */
    /* animation-timing-function: linear; */

}
/* 

@keyframes up-down-middle {
    50% {
        bottom: 46px;
    }
  } */



.telephony-grid__chart--right {
    position: absolute;
    bottom: 5px;
    right: 24px;
    /* border: 1px solid red; */
    /* animation: up-down-slow 2s infinite; */
    /* animation-timing-function: linear; */

}
/* 
@keyframes up-down-slow {
    50% {
      bottom: 40px;
    }
  } */