.social-icon {
	border-radius: 30px;
	transition: transform 0.2s ease-in-out;
	cursor: pointer;
}

/* Анимация активируется через класс .animate */
.social-icon.animate:nth-child(2n) {
	animation: keyframes1 0.5s infinite alternate ease-in-out;
	transform-origin: 50% 10%;
}

@keyframes keyframes1 {
	0% {
		transform: rotate(-1deg);
	}

	100% {
		transform: rotate(2deg);
	}
}


.social-icon.animate:nth-child(2n-1) {
	animation: keyframes2 0.5s infinite alternate ease-in-out;
	transform-origin: 30% 5%;
}


@keyframes keyframes2 {
	0% {
		transform: rotate(1deg);
	}

	100% {
		transform: rotate(-2deg);
	}
}

.social-icon.initial:nth-child(2n) {
	animation: initial-keyframe1 0.5s linear forwards !important;


}

.social-icon.initial:nth-child(2n-1) {
	animation: initial-keyframe2 0.8s linear forwards !important;


}

@keyframes initial-keyframe1 {
	0% {
		transform: rotate(var(--deg-value));
	}

	50% {
		transform: rotate(calc(var(--deg-value) * -1));
	}

	100% {
		transform: rotate(0deg);
	}
}


@keyframes initial-keyframe2 {
	0% {
		transform: rotate(var(--deg-value));
	}

	50% {
		transform: rotate(calc(var(--deg-value) * -1));
	}

	100% {
		transform: rotate(0deg);
	}
}