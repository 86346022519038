@font-face {
    font-family: "Poppins";
    src: url("/src/assets/fonts/Poppins-SemiBold.woff") format("woff"),
         url("/src/assets/fonts/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }
/*   
@font-face {
    font-family: "Poppins";
    src: url("/src/assets/fonts/Poppins-Medium.woff") format("woff"),
         url("/src/assets/fonts/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
   */

  @font-face {
    font-family: "Futura PT";
    src: url("/src/assets/fonts/FuturaPT-Book.woff") format("woff"),
         url("/src/assets/fonts/FuturaPT-Book.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  

  @font-face {
    font-family: "Futura PT";
    src: url("/src/assets/fonts/FuturaPT-Medium.woff") format("woff"),
         url("/src/assets/fonts/FuturaPT-Medium.ttf") format("truetype");
    font-weight: 450;
    font-style: normal;
  }
  

    
  @font-face {
    font-family: "Futura PT";
    src: url("/src/assets/fonts/FuturaPT-Demi.woff") format("woff"),
         url("/src/assets/fonts/FuturaPT-Demi.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  

  
  @font-face {
    font-family: "Futura PT";
    src: url("/src/assets/fonts/FuturaPT-Heavy.ttf") format("woff"),
         url("/src/assets/fonts/FuturaPT-Heavy.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  

  
  @font-face {
    font-family: "Futura PT";
    src: url("/src/assets/fonts/FuturaPT-Bold.woff") format("woff"),
         url("/src/assets/fonts/FuturaPT-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }



  body {
    font-family: "Futura PT";
    margin: 0;
    /* background: linear-gradient(135.65deg, #DB810E 15.38%, #933800 77.31%); */
    background: linear-gradient(332.78deg, #EBEBEB 32.79%, #F7F7F7 52.61%, #EBEBEB 73.53%);
}

    
  a {
    font-family: "Futura PT", Arial, sans-serif;
    text-decoration: none;
}

.link {
    /* font-size: 24px; */
    /* font-size: 1.6vw; */
    font-size: 1.5em;

    font-weight: 400;
    color: #3F66DB;

    &:hover {
        transition-duration: 1s;
        opacity: .5;
    }
}

.link--light {
  color: #FFDBB8;
}

.link--dark {
  color: #3F66DB;
}

.link-arrow {
  padding-left: 14px;
  vertical-align: -3px;
}

.ui-section {
  margin: 0 auto;
  padding: 0 100px;
  max-width: 1300px;
}

/* 
.cock {
  position: absolute;
  width: 2px;
  height: 1000px;
  background: #000000;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
} */

/* 
.cock2 {
  position: absolute;
  width: 100%;
  height: 2px;
  background: #000000;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
} 
 */


/* swiper override */

/* 
.carousel-container {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.review-card {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 18px;
  font-weight: bold;
} */



.login-section {

  .MuiPaper-root {

    margin-top: 12px;
    font-size: 16px;
    font-weight: 450;
  }


  .MuiMenuItem-root {
    vertical-align: middle;
    padding: 6px !important;
    height: 36px;
    transition: .5s;
  }


  .MuiMenuItem-root:hover {
    background: #FFFFFF33 !important;
    border-radius: 120px !important;
    /* padding: 6px !important;
    line-height: 36px !important; */
  }
}

/* 

.telephony-grid__earth { 
	background-position: 0px 0px;
	animation: textureSpinreverse 8s linear infinite;
}
.telephony-grid__earth { 
	background-position: 0px -220px;
    animation: textureSpin 8s linear infinite;
}

@keyframes textureSpin {
	from {
		transform: translateX(0);
	}
	to {
        transform: translateX(-47.5%);
	}
}
@keyframes textureSpinreverse {
	from {
        transform: translateX(-47.5%);
	}
	to {
		transform: translateX(0);
	}
} */

html {
  scroll-behavior: smooth;
}

html, body {
  font-size: min(1vw, 16px);

}

body {
  background-image: linear-gradient(178deg, #EBEBEB, #151515 50%);
}

.text {
  /* color: red !important; */
  /* margin-top: 0;
  margin-bottom: 0;
  margin-right: 1vw !important;
  font-size: 4.4vw !important; */
}

.img-cloud {
  width: 100%;
  /* margin: 0 auto; */
  /* width: 62.5vw; */
  /* max-width: 900px;  */
  /* Чтобы не становилась больше макетного размера */
  /* position: absolute; */
  /* top: 40px; */
  /* left: 50%; */
  /* left: calc(50% + 4vw);
  transform: translateX(-50%); */
}

.hero-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 125px); /* высота меню */
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
}

.hero-image {
  position: absolute;
  width: 62.5vw;
  max-width: 1138px;
  left: calc(50% + 6.3vw);
  transform: translateX(-50%);
  margin: 0 auto;
}

/* 
.img-cloud__wrapper {
  position: absolute;
  left: calc(50% + 4vw);
  transform: translateX(-50%);
  margin: 0 auto;
  width: 62.5vw;
  max-width: 900px; 
} */


.hero-text {
  position: absolute;
  margin-top: -10vw;
  left: calc(50% - 20vw);
  transform: translateX(-50%);
}

.hero-text h1 {
  font-size: clamp(1.5rem, 3vw, 3rem);
  /* line-height: 1.2; */
}


@media (max-width: 768px) {
  .hero-image {
    width: 80vw;
    max-width: 100%; /* На совсем маленьких экранах пусть занимает всю ширину */
  }
}

/* блокировка для мобилок */
body {
  min-width: 1366px;
  width: 100%;
}