/* .place-block {
    height: 400px;
    background: linear-gradient(332.78deg, #EBEBEB 32.79%, #F7F7F7 52.61%, #EBEBEB 73.53%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .MuiTypography-root{ 
        color: #151515;
    }
} */


.place-block--hidden {
    height: 0;
    overflow: hidden;
}

.place-container {
    height: 400px;
    background: linear-gradient(332.78deg, #EBEBEB 32.79%, #F7F7F7 52.61%, #EBEBEB 73.53%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .MuiTypography-root{ 
        color: #151515;
    }
}


.place-container--expanded {
    height: 400px;
}